<template>
    <!-- Skeleton -->
    <!-- <v-main v-if="isStoreState('loadingDocument', 'PENDING')">
        <header class="tw-bg-white tw-pt-13 tw-pb-12 tw-px-4">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
                <v-skeleton-loader type="text" class="tw-max-w-xs"></v-skeleton-loader>
                <v-skeleton-loader type="heading" class="tw-mt-2"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar" class="tw-max-w-xs tw-mt-6 tw--ml-4"></v-skeleton-loader>
            </div>
        </header>

        <main class="tw-mt-8 tw-px-4 tw-mb-24">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
                <div class="tw-flex tw-justify-center">
                    <v-progress-circular :size="48" class="tw-text-gray-600" indeterminate></v-progress-circular>
                </div>
            </div>
        </main>
    </v-main> -->

    <!-- Page -->

    <k-layout v-if="post" class="tw-bg-white">
        <main class="tw-mt-28 tw-px-4 tw-mb-24">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-5xl">
                <section class="tw-flex tw-flex-col tw-items-center">
                    <div class="tw-max-w-prose tw-flex tw-flex-col tw-items-center">
                        <category-chip :category="post.category" />
                        <h1 class="tw-heading-1 tw-text-center tw-mt-5">{{ postGetAttr(post, "title", locale) }}</h1>
                        <p class="tw-heading-5 tw-mt-4">{{ post.author.name }}</p>
                        <p class="tw-mt-2 tw-text-gray-800">{{ date(post.publishedAt) }}</p>
                    </div>

                    <div v-if="post.featuredImage" class="tw-aspect-ratio-19:10 tw-w-full tw-rounded-sm tw-overflow-hidden tw-mt-16">
                        <img :src="post.featuredImage" class="tw-object-cover tw-object-center" />
                    </div>

                    <div class="tw-overflow-hidden tw-max-w-full">
                        <article id="content" class="tw-prose lg:tw-prose-lg tw-mt-16 tw-mx-auto" v-html="postGetAttr(post, 'content', locale)"></article>
                    </div>

                    <div v-if="post.spotifyId" class="tw-w-full tw-max-w-prose">
                        <iframe
                            :src="`https://open.spotify.com/embed/episode/${post.spotifyId}`"
                            width="100%"
                            height="232"
                            frameBorder="0"
                            allowfullscreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        ></iframe>
                    </div>

                    <div class="tw-flex tw-flex-row tw-space-x-2 tw-mt-4" v-if="post.appleUrl || post.googleUrl">
                        <a :href="post.appleUrl" v-if="post.appleUrl" target="_blank" rel="noopener noreferrer">
                            <img src="/web/badge_apple_podcasts.svg" alt="" />
                        </a>
                        <a :href="post.googleUrl" v-if="post.googleUrl" target="_blank" rel="noopener noreferrer">
                            <img src="/web/badge_google_podcasts.svg" alt="" />
                        </a>
                    </div>

                    <aside class="tw-max-w-prose tw-w-full tw-bg-gray-300 tw-rounded-sm tw-mt-17">
                        <div class=" tw-flex tw-flex-col tw-items-center tw-p-4 lg:tw-p-8">
                            <div v-if="post.author.avatar" class="tw-rounded-full tw-overflow-hidden tw-w-16 tw-h-16">
                                <img :src="post.author.avatar" class=" tw-h-full tw-w-full tw-object-cover tw-object-center" />
                            </div>
                            <p class="tw-heading-3 tw-mt-4">{{ post.author.name }}</p>
                            <p class="tw-mt-4 tw-text-center">{{ post.author.description }}</p>
                        </div>
                    </aside>
                </section>

                <section class="tw-mt-20 tw-max-w-prose tw-mx-auto">
                    <Disqus ref="disqus" shortname="koderia" lang="sk" :pageConfig="disqusPageConfig" />
                </section>

                <section class="tw-mt-20">
                    <h2 class="tw-heading-2">{{ $t("blog.detail.subtitle") }}</h2>

                    <div class="tw-mt-6 tw-grid tw-gap-6 tw-grid-cols-1 lg:tw-grid-cols-3">
                        <div v-for="post in posts" :key="post.id">
                            <post-preview :post="post" />
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </k-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { Disqus } from "vue-disqus";
import PostPreview from "./../components/post-preview";
import CategoryChip from "./../components/category-chip";
import { postGetAttr } from "@web/blog/utils";

export default {
    components: { Disqus, CategoryChip, PostPreview },
    created() {
        this.$vuetify.goTo(0);
    },
    computed: {
        ...mapGetters("BLOG", ["getPostBySlug", "isStoreState", "allPosts"]),
        locale() {
            return this.$store.getters.appLocale;
        },
        post() {
            const { slug } = this.$route.params;
            return this.getPostBySlug(slug);
        },
        posts() {
            return this.allPosts
                .filter(post => post.id !== this.post.id)
                .sort(() => 0.5 - Math.random())
                .slice(0, 3);
        },
        disqusPageConfig() {
            if (this.post) {
                return {
                    title: this.post.title,
                    identifier: this.post.id,
                    url: window.location.origin + window.location.pathname
                };
            }

            return {};
        }
    },
    watch: {
        "$route.param.slug": {
            immediate: true,
            handler() {
                if (this.$refs.disqus) {
                    this.$refs.disqus.reset();
                }
            }
        },
        "post.content": {
            immediate: true,
            handler(content) {
                if (content) {
                    this.processAnchors();
                }
            }
        },
        "post": {
            immediate: true,
            handler(post) {
                if (!post) {
                    const { slug } = this.$route.params;
                    const { key } = this.$route.query;
                    this.$store.dispatch("BLOG/fetchInitialPosts");
                    this.$store.dispatch("BLOG/fetchPostBySlug", { slug, accessKey: key }).then(() => {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                if (!this.post) {
                                    this.notFound();
                                }
                            }, 100);
                        });
                    });
                }
            }
        }
    },
    methods: {
        postGetAttr,
        date(v) {
            if (!v) return;
            return new Date(Date.parse(v)).toLocaleDateString(this.locale, {
                year: "numeric",
                month: "long",
                day: "numeric"
            });
        },
        processAnchors() {
            this.$nextTick(() => {
                const anchors = document.querySelectorAll("#content a");
                anchors.forEach(anchor => {
                    anchor.setAttribute("target", "_blank");
                });
            });
        },
        notFound() {
            this.$router.push({ name: "Page404", params: { "0": "" } }).catch(() => {});
        }
    }
};
</script>
