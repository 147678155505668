<template>
    <div
        class="category-chip tw-px-1 tw-pt-0.5 tw-pb-1 tw-leading-none tw-text-white tw-text-xs tw-inline-block tw-rounded-sm"
        :style="`--color-a: ${category.color_a}; --color-b: ${category.color_b};`"
    >
        {{ category.name }}
    </div>
</template>

<script>
export default {
    props: {
        category: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.category-chip {
    background-image: linear-gradient(63deg, var(--color-a) 0%, var(--color-b) 100%) !important;
}
</style>
